<template>
  <div class="mt-5">
    <b-container v-if="!loadingStore">
      <div>
        <div>
          <div>
            <h2 class="text-primary font-size-58 font-weight-bold mb-3">
              <!--{{$t('marketplace.camelSuppliesStores')}}-->
              {{ nameOfStore }}
            </h2>
          </div>
          <filter-stores @changeLayout="changeLayout">
            <h3 class="text-primary font-weight-bold">
              {{ $t("main.filter") }}
            </h3>
            <main-select
              :reduce="(category) => category.id"
              :options="allCountries"
              class="flex-grow-1 mb-0 w-100"
              :placeholder="$t('main.country')"
              v-model="country"
              dir="rtl"
              label="name"
            />
            <main-select
              :reduce="(city) => city.id"
              :options="allCities"
              v-model="city"
              label="name"
              class="flex-grow-1 mb-0 w-100"
              :placeholder="$t('main.city')"
              dir="rtl"
            />
            <main-select
              :options="sort"
              class="flex-grow-1 mb-0 w-100"
              :placeholder="$t('main.sort')"
              label="name"
              dir="rtl"
              :reduce="(data) => data.value"
              @selecting="selectSortData"
            />
            <b-button
              @click="getAllStores"
              variant="primary"
              class="font-size-18 iq-border-radius-5 d-flex align-items-center gap_1"
            >
              {{ $t("main.search") }}
              <i class="las la-search font-weight-bold d-inline-block"></i
            ></b-button>
          </filter-stores>
          <div>
            <b-row
              v-if="loadingStore"
              class="d-flex align-items-center justify-content-center mt-5"
            >
              <b-spinner large type="grow" variant="primary"></b-spinner>
            </b-row>
            <div v-else>
              <b-row v-if="allStores.length > 0">
                <b-col
                  v-for="(store, key) in allStores"
                  :key="key"
                  class="mb-4"
                  cols="12"
                  :class="[
                    {
                      'col-lg-3 col-md-6 active': selectedView === 'storesGrid',
                      'col-md-12': selectedView !== 'storesGrid',
                    },
                  ]"
                >
                  <transition
                    name="router-anim"
                    :enter-active-class="`animated zoomIn`"
                    mode="out-in"
                    :leave-active-class="`animated zoomOut`"
                  >
                    <component
                      :storeItem="store"
                      :is="selectedView"
                    ></component>
                  </transition>
                </b-col>
              </b-row>
              <div v-else>
                <not-found-com />
              </div>
            </div>
          </div>
          <!-- pagination -->
          <div class="d-flex justify-content-center">
            <b-pagination
              v-if="pagination.totalItems > pagination.itemsPerPage"
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              :per-page="pagination.itemsPerPage"
              first-number
              last-number
              class="mb-3 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              align="right"
              @input="getAllStores(pagination.currentPage)"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </b-container>
    <!--    <b-container v-else-if="!loadingStore && allStores.length <= 0" class="d-flex align-items-center justify-content-center">-->
    <!--      <b-spinner large type="grow" variant="primary"></b-spinner>-->
    <!--    </b-container> -->
    <b-container
      v-else
      class="d-flex align-items-center justify-content-center"
    >
      <b-spinner large type="grow" variant="primary"></b-spinner>
    </b-container>
  </div>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import storesGrid from "@/modules/servicePages/marketplace/components/allStores/storesGrid";
import storeList from "@/modules/servicePages/marketplace/components/allStores/storesList";
import filterStores from "../../components/filterMarketPlace";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import notFoundCom from "@/modules/servicePages/marketplace/components/notFoundCom";
import citiesAndCountriesMixin from "@/mixins/countriesAndCities";
export default {
  components: { notFoundCom, storesGrid, filterStores, storeList },
  mixins: [citiesAndCountriesMixin],
  data() {
    return {
      pagination: {},
      selectedView: "storesGrid",
      allStores: [],
      country: "",
      city: "",
      loadingStore: true,
      selectSort: "DESC",
      nameOfStore: "",
      sort: [
        {
          name: "الكل",
          value: "",
        },
        {
          name: "من الأحدث للأقدم",
          value: "ASC",
        },
        {
          name: "من الأقدم للأحدث",
          value: "DESC",
        },
      ],
    };
  },
  methods: {
    getAllStores(page = 1) {
      this.loadingStore = true;
      marketPlace
        .getAllStores(
          this.$route.params.id,
          this.country,
          this.city,
          this.selectSort,
          page
        )
        .then((res) => {
          this.allStores = res.data.data;
          this.nameOfStore = res.data?.category;
          this.pagination = res.data.meta;
        })
        .finally(() => {
          this.loadingStore = false;
        });
    },
    getPagination(page = 1) {
      marketPlace
        .getAllStores(
          this.$route.params.id,
          this.country,
          this.city,
          this.selectSort,
          page
        )
        .then((res) => {
          this.allStores = res.data.data;
          this.pagination = res.data.meta;
          console.log("pagination market place is ", this.pagination);
        });
    },
    selectSortData(selectSort) {
      this.selectSort = selectSort.value;
    },
    changeLayout(layout) {
      if (layout === "vertical") {
        this.selectedView = "storesGrid";
      } else {
        this.selectedView = "storeList";
      }
    },
  },
  created() {
    this.getPagination();
    setTimeout(() => {
      this.getAllStores();
    }, 2000);
  },
  mounted() {
    core.index();
  },
  /*  beforeRouteEnter (to, from, next) {
    console.log('tototototototo', to)
    to.meta.breadCrumb.push({
      text: 'marketplace.stores',
      active: true
    })
  } */
};
</script>
<Style>
</Style>
