<template>
  <b-container>
    <router-link
      @click.native="saveStoreData(storeItem)"
      :to="{
        name: 'storeProfile',
        params: { id: this.$route.params.id, slogan: storeItem.url },
      }"
    >
      <iq-card class="py-0 position-relative">
        <b-row>
          <b-col md="4"
            ><div
              class="store-banner position-relative iq-border-radius-10"
              :style="{
                'background-size': 'cover',
                'background-position': 'center',
                'background-image': `url(${
                  storeItem.cover ? storeItem.cover : defaultImg
                })`,
              }"
            >
              <div
                class="small-image iq-border-radius-10"
                :style="{
                  'background-size': 'cover',
                  'background-position': 'center',
                  'background-color': '#fff',
                  'background-repeat': 'no-repeat',
                  'background-image': `url(${
                    storeItem.logo ? storeItem.logo : logoImg
                  })`,
                }"
              ></div></div
          ></b-col>
          <b-col md="8">
            <div
              class="flex-column justify-content-start align-items-center mt-4"
            >
              <h2 class="text-primary font-size-38">{{ storeItem.name }}</h2>
              <p class="font-size-20 text-muted mt-3 mb-3 w-75">
                {{
                  storeItem.description.length >= 100
                    ? storeItem.description.slice(0, 100) + "...."
                    : storeItem.description || storeItem.about.length >= 100
                    ? storeItem.about.slice(0, 100) + "...."
                    : storeItem.about || description
                }}
              </p>
              <div class="d-flex align-items-center">
                <div class="d-flex gap_1">
                  <img
                    :src="require('@/assets/images/ibbil/truck.svg')"
                    class="width-2"
                  />
                  <span class="ms-1 font-size-23 text-primary"
                    >{{ $t("marketplace.driveTo") }} 4
                    {{ $t("main.cities") }}</span
                  >
                </div>
                <div class="d-flex gap_1 mr-5">
                  <img
                    :src="require('@/assets/images/ibbil/productProfile.svg')"
                    class="width-2"
                  />
                  <span class="ms-1 font-size-23 text-primary"
                    >{{
                      storeItem.items_count ? storeItem.items_count : comment
                    }}
                    {{ $t("main.product") }}</span
                  >
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </iq-card>
    </router-link>
  </b-container>
</template>

<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";

export default {
  name: "storesList",
  props: ["storeItem"],
  data() {
    return {
      defaultImg: require("@/assets/images/ibbil/homePage/cover1.jpeg"),
      logoImg: require("@/assets/images/ibbil/images/camel.png"),
      description: "لا يوجد وصف لهذا المتجر ",
      comment: 5,
      views: 12,
      likes: 8,
    };
  },
  methods: {
    saveStoreData(payload) {
      this.$store.commit("cart/changeSelectedStore", payload);
    },
  },
  mounted() {
    core.index();
  },
};
</script>

<style lang="less">
.store-banner {
  width: 327px;
  height: 245px;
}
.store-banner {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
  }
}
.small-image {
  width: 145px;
  height: 145px;
  position: absolute;
  left: -75px;
  top: 20%;
}
.font-size-38 {
  font-size: 38px !important;
}
.font-size-23 {
  font-size: 23px !important;
}
</style>
