var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('router-link',{attrs:{"to":{
      name: 'storeProfile',
      params: { id: this.$route.params.id, slogan: _vm.storeItem.url },
    }},nativeOn:{"click":function($event){return _vm.saveStoreData(_vm.storeItem)}}},[_c('iq-card',{staticClass:"py-0 position-relative"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"store-banner position-relative iq-border-radius-10",style:({
              'background-size': 'cover',
              'background-position': 'center',
              'background-image': ("url(" + (_vm.storeItem.cover ? _vm.storeItem.cover : _vm.defaultImg) + ")"),
            })},[_c('div',{staticClass:"small-image iq-border-radius-10",style:({
                'background-size': 'cover',
                'background-position': 'center',
                'background-color': '#fff',
                'background-repeat': 'no-repeat',
                'background-image': ("url(" + (_vm.storeItem.logo ? _vm.storeItem.logo : _vm.logoImg) + ")"),
              })})])]),_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"flex-column justify-content-start align-items-center mt-4"},[_c('h2',{staticClass:"text-primary font-size-38"},[_vm._v(_vm._s(_vm.storeItem.name))]),_c('p',{staticClass:"font-size-20 text-muted mt-3 mb-3 w-75"},[_vm._v(" "+_vm._s(_vm.storeItem.description.length >= 100 ? _vm.storeItem.description.slice(0, 100) + "...." : _vm.storeItem.description || _vm.storeItem.about.length >= 100 ? _vm.storeItem.about.slice(0, 100) + "...." : _vm.storeItem.about || _vm.description)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex gap_1"},[_c('img',{staticClass:"width-2",attrs:{"src":require('@/assets/images/ibbil/truck.svg')}}),_c('span',{staticClass:"ms-1 font-size-23 text-primary"},[_vm._v(_vm._s(_vm.$t("marketplace.driveTo"))+" 4 "+_vm._s(_vm.$t("main.cities")))])]),_c('div',{staticClass:"d-flex gap_1 mr-5"},[_c('img',{staticClass:"width-2",attrs:{"src":require('@/assets/images/ibbil/productProfile.svg')}}),_c('span',{staticClass:"ms-1 font-size-23 text-primary"},[_vm._v(_vm._s(_vm.storeItem.items_count ? _vm.storeItem.items_count : _vm.comment)+" "+_vm._s(_vm.$t("main.product")))])])])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }